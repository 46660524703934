import React from "react";
import "./about.css";
import ME from "../../Assets/me.jpg";
import { FaAward } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { VscFolderLibrary } from "react-icons/vsc";
const about = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">

        {/* Sirtf image hai isme */}
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div>

        {/* Right side ka details hai sme */}

        <div className="about__content">


          <div className="about__cards">


            <article className="card_elements">
              <FaAward className="about__icon" />
              <h5>Data Structures and Algorithms</h5>
              <small>120+ Problems on platforms like <a href="https://leetcode.com/dynamic099/" target="_blank"><button className="dsa">Leetcode</button> </a></small>
            </article>



            <article className="card_elements">
              <FiUsers className="about__icon" />
              <h5>CS Fundamentals</h5>
              <small>Networking, OS, DBMS, OOPS</small>
            </article>



            <article className="card_elements">
              <VscFolderLibrary className="about__icon" />
              <h5>Industry Experience </h5>
              <small>DevOps Intern at AlphaOri Technolgies <a href="https://drive.google.com" target="_blank"><button className="dsa">Certificate</button></a></small>
            </article>
          </div>




          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Commodi
            qui a quae temporibus distinctio delectus quo adipisci nisi quam
            ipsam. Adipisci sapiente eligendi laborum ducimus culpa perspiciatis
            iure asperiores repellendus!
          </p>




          <a href="#contact" className="btn btn-primary">
            Let's Talk
          </a>




        </div>
      </div>
    </section>
  );
};

export default about;

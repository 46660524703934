import React from "react";
import "./portfolio.css";
import Projview from '../../Assets/Portfolio-Website.png';
import LinkedinClone from '../../Assets/Linkedin-Clone.png'
import rschpaper from '../../Assets/Research-Paper.png'
import indbuy from '../../Assets/Indiabudgetbuy.png'

const data = [
  {
    id: 1,
    image: Projview,
    title : 'Personal Portfolio Website',
    github: 'https://github.com/rangercoder/Portfolio-Website',
    demo: 'https://pushkerprakash.com/'
  },

  {
    id: 2,
    image: LinkedinClone,
    title : 'Linkedin Clone',
    github: 'https://github.com/rangercoder/Linkedin_Clone',
    demo: 'https://pushkerprakash.com/'
  },

  {
    id: 3,
    image: rschpaper,
    title : 'Research Paper',
    github: 'https://drive.google.com/file/d/1r_8v8ZbG_vVaD-PwW96wMWlD6_GDv1Ig/view?usp=sharing',
    demo: 'https://drive.google.com/file/d/1vvZyDAtkW-4unzp7MoL5rFQN4lvaDWSg/view?usp=share_link"'
  },

  {
    id: 4,
    image: indbuy,
    title : 'India Budget Buy',
    github: 'https://web.archive.org/web/20201102192850/http://indiabudgetbuy.com/',
    demo: 'https://pushkerprakash.com/'
  },

   
]

const portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>
      <div className="container portfolio__container">
        {/* {
          data.map(({id,image,title,github,demo})=>
          {
            return(
            <article key={id} className="portfolio__item">
          <div className="portfolio__item-image">
            <img src = {image} alt =""/>
          </div>
          <h3>{title}</h3>
          <div className='portfolio__item-cta'>
          <a href = {github} className='btn' target='_blank'>Github</a>
          <a href  ={demo} className='btn btn-primary' target='_blank'>Live Demo</a>
          </div>
        </article>
            )
          })
        } */}
        <article className="portfolio__item">
          <div>
          <div className="portfolio__item-image">
            <img src = {Projview} alt =""/>
          </div>
          <h3>This is a portfolio item title</h3>
          </div>
          <div className='portfolio__item-cta'>
          <a href ="https://github.com/rangercoder/Portfolio-Website" className='btn' target='_blank'>Github</a>
          <a href ="https://pushkerprakash.com/" className='btn btn-primary' target='_blank'>Live Demo</a>
          </div>
        </article>
        <article className="portfolio__item">
          <div>
          <div className="portfolio__item-image">
            <img src = {LinkedinClone} alt =""/>
          </div>
          <h3>This is a portfolio item title</h3>
          </div>
          <div className='portfolio__item-cta'>
          <a href ="https://github.com/rangercoder/Linkedin_Clone" className='btn' target='_blank'>Github</a>
          <a href ="https://pushkerprakash.com/" className='btn btn-primary' target='_blank'>Live Demo</a>
          </div>
        </article><article className="portfolio__item">
          <div>
          <div className="portfolio__item-image">
            <img src = {rschpaper} alt =""/>
          </div>
          <h3>This is a portfolio item title</h3>
          </div>
          <div className='portfolio__item-cta'>
          <a href ="https://drive.google.com/file/d/1r_8v8ZbG_vVaD-PwW96wMWlD6_GDv1Ig/view?usp=sharing" className='btn' target='_blank'>Certificate</a>
          <a href ="https://drive.google.com/file/d/1vvZyDAtkW-4unzp7MoL5rFQN4lvaDWSg/view?usp=share_link" className='btn btn-primary' target='_blank'>Link</a>
          </div>
        </article><article className="portfolio__item">
          <div>
          <div className="portfolio__item-image">
            <img src = {indbuy} alt =""/>
          </div>
          <h3>This is a portfolio item title</h3>
          </div>
          <div className='portfolio__item-cta'>
          <a href ="https://web.archive.org/web/20201102192850/http://indiabudgetbuy.com/" className='btn' target='_blank'>WayBack</a>
          <a href ="https://pushkerprakash.com/" className='btn btn-primary' target='_blank'>Live Demo</a>
          </div>
        </article>
      </div>
    </section>
  );
};

export default portfolio;

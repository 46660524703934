import React from "react";
import "./footer.css";
import {FaFacebookF} from 'react-icons/fa'
import {FiInstagram} from 'react-icons/fi'
import {IoLogoTwitter} from 'react-icons/io'

const footer = () => {
  return (
    <footer>
      <a href="#" className="footer__logo">
        Pushker Prakash
      </a>
      <ul className="permalinks">
        <li>
          <a href="#">Home</a>
        </li>
        <li>
          <a href="#about">About</a>
        </li>
        <li>
          <a href="#experience">Experience</a>
        </li>
        <li>
          <a href="#services">Services</a>
        </li>
        <li>
          <a href="#portfolio">Portfolio</a>
        </li>
        <li>
          <a href="#contact">Contact</a>
        </li>
      </ul>
      <div className='footer__socials'>
        <a href = "https://www.facebook.com/pushker.prakash.9"><FaFacebookF/></a>
        <a href = "https://www.instagram.com/_pushker_prakash_/"><FiInstagram/></a>
        <a href = "https://twitter.com/PrakashPushker"><IoLogoTwitter/></a>
      </div>
      <div className="footer__copyright">
        <small>
          &copy; Pushker Prakash. All rights reserved.
        </small>
      </div>
    </footer>
  );
};

export default footer;

import React from "react";
import "./experience.css";
import { BsPatchCheckFill } from "react-icons/bs";
const experience = () => {
  return (
    <section id="experience">
      <h5> What Skills I have</h5>
      <h2> My Experince </h2>
      <div className="container experince__container">
        <div className="experince__frontend">
          <h3> Frontend </h3>
          <div className="details">
            <article className="detail_inside">
                <div className="inside-icon">
                  <BsPatchCheckFill/>
                </div>
              <div>
                <h4> HTML</h4>
                <small className="text-light">Experinced</small>
              </div>
            </article>
            <article className="detail_inside">
              <div className="inside-icon">
                <BsPatchCheckFill/>
              </div>
              <div>
                <h4> JavaScript</h4>
                <small className="text-light">Intermediate</small>
              </div>
            </article>
            <article className="detail_inside">
              <div className="inside-icon">
                <BsPatchCheckFill/>
              </div>
              <div>
                <h4> CSS</h4>
                <small className="text-light">Experinced</small>
              </div>
            </article>
            <article className="detail_inside">
              <div className="inside-icon">
                <BsPatchCheckFill/>
              </div>
              <div>
                <h4> Bootstrapped</h4>
                <small className="text-light">Experinced</small>
              </div>
            </article>
            <article className="detail_inside">
              <div className="inside-icon">
                <BsPatchCheckFill/>
              </div>
              <div>
                <h4> Tailwind</h4>
                <small className="text-light">Experinced</small>
              </div>
            </article>
            <article className="detail_inside">
              <div className="inside-icon">
                <BsPatchCheckFill/>
              </div>
              <div>
                <h4> React</h4>
                <small className="text-light">Experinced</small>
              </div>
            </article>
          </div>
        </div>
        <div className="experince__backend">
          <h3> Backend Developement </h3>
          <div className="details">
            <article className="detail_inside">
              <div className="inside-icon">
                <BsPatchCheckFill/>
              </div>
              <div>
                <h4> NodeJs</h4>
                <small className="text-light">Experinced</small>
              </div>
            </article>
            <article className="detail_inside">
              <div className="inside-icon">
                <BsPatchCheckFill/>
              </div>
              <div>
                <h4> MongoDb</h4>
                <small className="text-light">Intermediate</small>
              </div>
            </article>
            <article className="detail_inside">
              <div className="inside-icon">
                <BsPatchCheckFill/>
              </div>
              <div>
                <h4> PHP</h4>
                <small className="text-light">Experinced</small>
              </div>
            </article>
            <article className="detail_inside">
              <div className="inside-icon">
                <BsPatchCheckFill/>
              </div>
              <div>
                <h4> MySql</h4>
                <small className="text-light">Experinced</small>
              </div>
            </article>
            <article className="detail_inside">
              <div className="inside-icon">
                <BsPatchCheckFill/>
              </div>
              <div>
                <h4> Python</h4>
                <small className="text-light">Experinced</small>
              </div>
            </article>
            <article className="detail_inside">
              <div className="inside-icon">
                <BsPatchCheckFill/>
              </div>
              <div>
                <h4> Django</h4>
                <small className="text-light">Experinced</small>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};

export default experience;

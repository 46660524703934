import React from "react";
import "./header.css";
import CTA from "./CTA";
import ME from "../../Assets/profile_pic_new-removebg-preview.png";
import HeaderSocials from "./HeaderSocials";
import { useTypewriter, Cursor } from "react-simple-typewriter";

const Header = () => { 
  const [text] = useTypewriter({
    words: ['Software Developer', 'Coder', 'Machine Learning Enthusiast'],
    loop: {},
    typeSpeed: 100,
    deleteSpeed:80,
  });

  return (
    <header>
      <div className="container header_container">
        <h5> Hello I'm</h5>
        <h1>Pushker Prakash </h1>
        <h3 >
          <span style={{fontWeight: 'bold', color: '#0e7490' }}>
            {text}
          </span>
          <span style={{color: 'red' }}>
            <Cursor cursorStyle='_' />
          </span>
        </h3>
        <CTA />
        <HeaderSocials />

        <div className="me">
          <img src={ME} alt="me" />
        </div>

        <a href="#portfolio" className="scroll__down">
          Scroll Down
        </a>
      </div>
    </header>
  );
};

export default Header; 
